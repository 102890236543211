<template>
  <NotSecureContext v-if="!isSecureContext" />
  <el-main style="padding: 0" v-else>
    <el-row :gutter="20" style="margin-top: 10px; width: 100%">
      <el-col :span="16">
        <el-card style="height: calc(100vh - 170px); overflow-y: auto">
          <el-search-table-pagination
            ref="searchTable"
            :fetch="fetchMessageData"
            :page-sizes="[10, 20]"
            :columns="tableColumns"
            :form-options="tableFormSearch"
            total-field="total"
            list-field="list"
          >
          </el-search-table-pagination>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-row
          style="margin-left: 0; margin-right: 0"
          :gutter="10"
          type="flex"
          justify="flex-start"
        >
          <el-date-picker
            v-model="datePicked"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            format="dd/MM/yyyy"
            :picker-options="datePickerOptions"
            :default-time="['00:00:00', '00:00:00']"
          />
          <el-tooltip effect="dark" content="Download" placement="bottom">
            <el-button
              style="margin-left: 10px"
              type="primary"
              :loading="isDownloading"
              icon="el-icon-download"
              @click="downloadLogs"
            ></el-button>
          </el-tooltip>
        </el-row>
        <el-row :gutter="10" type="flex" justify="flex-start">
          <el-input
            style="margin-left: 10px; width: 200px"
            placeholder="File password"
            suffix-icon="el-icon-edit"
            v-model="generatedPassword"
            :disabled="true"
          >
          </el-input>
          <el-tooltip effect="dark" content="Copy generated password" placement="bottom">
            <el-button
              style="margin-left: 10px"
              icon="el-icon-document-copy"
              @click="copyPassword"
            />
          </el-tooltip>
        </el-row>
        <el-card
          v-loading="loadingChart"
          element-loading-text="Loading message chart"
          style="height: calc(100vh - 280px); overflow-y: auto"
          text
        >
          <DoughnutChart
            style="width: 85%; margin: auto"
            :datasets="chartDatasets"
            :labels="MESSAGE_STATUS_MAP"
            :category="MESSAGE_STATUS_MAP"
            :colors="colors"
            :options="{ legend: { display: true } }"
          />
        </el-card>
      </el-col>
    </el-row>
  </el-main>
</template>
<script>
import moment from "moment";
import DoughnutChart from "@/components/DoughnutChart";
import { exportToXLSX } from "@/helpers/xlsxHelper";
import secureContextMixin from "@/mixins/secureContext";

const MESSAGE_STATUS_MAP = ["Sent", "Delivered", "Read", "Failed", "Deleted"];
const MESSAGE_TYPE_MAP = [
  {
    name: "Template message",
    value: true,
  },
  {
    name: "Session message",
    value: false,
  },
];
const INITIAL_COUNTER = [0, 0, 0, 0, 0];
const MESSAGE_STATUS_TAB_PAGE_SIZE = 20;

export default {
  mixins: [secureContextMixin],
  components: {
    DoughnutChart,
  },
  name: "AnalyticsTab",
  props: {
    wa_id: String,
    wa_endpoint: String,
  },
  data() {
    return {
      filterParams: {
        pageSize: 10,
        pageIndex: 0,
      },
      totalMessage: 0,
      pageSize: MESSAGE_STATUS_TAB_PAGE_SIZE,
      currentPage: 1,
      colors: ["#1ecbe1", " #29d686 ", "#4db260", "#e8173a", "#3498DB"],
      messageLogs: [],
      chartDatasets: [{ data: [] }],
      generatedPassword: "",
      MESSAGE_STATUS_MAP,
      loadingChart: false,
      amountSpentLabels: [],
      amountSpentDataset: [],
      messagesLabels: [],
      messagesDataset: [],
      isDownloading: false,
      datePicked: [moment().utc().startOf("day").toDate(), moment().utc().toDate()],
      datePickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const start = new Date();
              start.setHours(-24);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const start = new Date();
              start.setDate(start.getDate() - 7);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last Year",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "All-Time",
            onClick(picker) {
              const start = new Date();
              start.setFullYear(1990, 1, 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableColumns: [
        {
          prop: "timestamp",
          label: "Time",
          width: 100,
          render: (row) => this.formatDateTime(row.timestamp),
        },
        {
          prop: "receiver_ws_number",
          label: "WA Number",
        },
        {
          prop: "status",
          label: "Status",
          width: 400,
          render: (row) =>
            `${MESSAGE_STATUS_MAP[row.status]}${row.error_log ? " | " + row.error_log : ""}`,
        },
        {
          prop: "template_id",
          label: "Template",
          render: (row) => (!_.isEmpty(row.template_id) ? row.template_id : ""),
        },
        {
          prop: "server_url",
          label: "Server",
          width: 400,
        },
      ],
      tableFormSearch: {
        inline: true,
        submitBtnText: "Search",
        forms: [
          {
            prop: "status",
            label: "Status",
            itemType: "select",
            options: [
              {
                value: "",
                label: "All",
              },
              ...MESSAGE_STATUS_MAP.map((status, i) => ({
                value: i,
                label: status,
              })),
            ],
          },
          {
            prop: "template",
            label: "Template",
            itemType: "select",
            options: [
              {
                label: "All",
                value: "",
              },
              ...MESSAGE_TYPE_MAP.map(({ name, value }) => ({
                label: name,
                value,
              })),
            ],
          },
          {
            prop: "server",
            label: "Server",
            itemType: "select",
            options: [
              {
                label: "All",
                value: "",
              },
              ...this.$store.state.whatsapp.servers.map((server) => ({
                label: server.url,
                value: server.url,
              })),
            ],
          },
        ],
      },
    };
  },
  computed: {
    timezone() {
      return this.$store.state.modules.whatsapp.timezone ?? 8;
    },
  },
  methods: {
    copyPassword() {
      this.$copyText(this.generatedPassword).then(
        function (e) {
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    getMessageParams(message) {
      if (!message.template_params || message.template_params === "undefined") {
        return "No params";
      }
      return message.template_params;
    },
    async downloadLogs() {
      const messages = await this.getLogsInDateRange();
      if (!messages || messages.length === 0) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: "No data to download",
        });
        return;
      }
      const downloadLogs = messages.map((message) => {
        return {
          Time: this.formatDateTime(message.timestamp),
          "To number": message.receiver_ws_number,
          "Message status": MESSAGE_STATUS_MAP[message.status],
          "Template ID": message.template_id,
          "Template Params": this.getMessageParams(message),
        };
      });

      const filename = this.$exportFilename("whatsapp_message_logs_with_status", "xlsx");
      const password = await this.$generatePassword();
      this.generatedPassword = password;
      exportToXLSX(downloadLogs, filename, password);
    },
    async updateAnalyticsData(start, end, filterParams) {
      const data = {
        start,
        end,
        limit: filterParams.pageSize,
        offset: (filterParams.pageIndex - 1) * filterParams.pageSize,
        server: filterParams.server,
        template: filterParams.template,
        status: filterParams.status,
      };

      return await this.$store
        .dispatch("GET_MESSAGE_LOGS_WITH_STATUS", {
          data,
        })
        .then(({ messages, status_count }) => {
          this.totalMessage = _.reduce(
            status_count,
            (result, value) => {
              return result + value.status_count;
            },
            0
          );
          this.loadingChart = false;
          this.messageLogs = messages.map((m) => {
            const { protocol, hostname } = new URL(m.server_url);
            const _server = protocol + "//" + hostname;
            return {
              ...m,
              _template: !!m.template_id,
              _server,
            };
          });
          let statusCounter = INITIAL_COUNTER.slice();
          _.forEach(status_count, (obj) => {
            statusCounter[obj.status] = obj.status_count;
          });
          this.chartDatasets = [{ data: statusCounter }];
          return {
            list: this.messageLogs,
            total: this.totalMessage,
          };
        })
        .catch((err) => {
          this.messageLogs = [];
          this.loadingChart = false;
        });
    },
    formatDateTime(date) {
      return moment(date).utcOffset(this.timezone).format("DD-MM-YYYY HH:mm:ss");
    },
    async updateMessageData() {
      const [startTimeStamp, endimeStamp] = this.datePicked;
      if (startTimeStamp && endimeStamp) {
        const { start, end } = this.syncTimezone({
          startDate: startTimeStamp,
          endDate: endimeStamp,
        });
        return await this.updateAnalyticsData(start, end, this.filterParams);
      }
      return { list: [], total: 0 };
    },
    async fetchMessageData(params) {
      this.filterParams = params;
      const data = await this.updateMessageData();
      return data;
    },
    getLogsInDateRange() {
      this.isDownloading = true;
      const [startTimeStamp, endTimeStamp] = this.datePicked;
      if (startTimeStamp && endTimeStamp) {
        const { start, end } = this.syncTimezone({
          startDate: startTimeStamp,
          endDate: endTimeStamp,
        });
        const diff = moment(moment.unix(end)).diff(moment.unix(start), "days");
        if (diff > 32) {
          this.$alert("Date range for download limited (maximum 31 days)");
          this.isDownloading = false;
          return;
        }
        const data = {
          start,
          end,
          server: this.filterParams.server,
          template: this.filterParams.template,
          status: this.filterParams.status,
        };
        return this.$store
          .dispatch("GET_MESSAGE_LOGS_WITH_STATUS", {
            data,
          })
          .then(({ messages, status_count }) => {
            this.isDownloading = false;
            return messages;
          })
          .catch((err) => {
            this.isDownloading = false;
            console.log(err.message);
          });
      }
    },
    changePage(pageNo) {
      this.currentPage = pageNo;
    },
    syncTimezone({ startDate, endDate }) {
      const cloneStart = _.cloneDeep(startDate);
      const cloneEnd = _.cloneDeep(endDate);

      cloneStart.setMinutes(
        -cloneStart.getTimezoneOffset() - cloneStart.getMinutes() - this.timezone * 60
      );
      cloneEnd.setDate(cloneEnd.getDate() + 1);
      cloneEnd.setHours(0, 0, 0, 0);
      cloneEnd.setMinutes(
        -cloneEnd.getTimezoneOffset() - cloneEnd.getMinutes() - this.timezone * 60
      );

      const start = Math.floor(cloneStart.getTime() / 1000);
      const end = Math.floor(cloneEnd.getTime() / 1000);
      return { start, end };
    },
  },
  mounted() {
    this.updateMessageData();
  },
  watch: {
    datePicked(newDateArr) {
      const [startDate, endDate] = newDateArr;
      const { start, end } = this.syncTimezone({
        startDate,
        endDate,
      });

      this.loadingChart = true;
      this.$refs["searchTable"].searchHandler();
      // return this.updateAnalyticsData(start, end, this.filterParams);
    },
  },
};
</script>
